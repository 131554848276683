.page {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* Titre */
.page h1 {
  font-size: 3em;
  margin-top: 27px;
  margin-bottom: 35px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
}

/* Images */
.page img {
  width: 100%;
  max-width: 520px;
  margin: 30px auto;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
}
/* Texte */
.page p {
  font-size: 130%;
  line-height: 30px;
  margin-bottom: 0px;
  margin-top: 34px;
  text-align: center;
  DISPLAY: inline-flex;
  /* ALIGN-ITEMS: center; */
  WIDTH: 48%;
  text-align: center;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  PADDING: 2%;
}

/* Bouton */
.page .btn {
  border-radius: 20px;
  background-color: #1c2747;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 2%;
  margin-block-start: 24%;
  margin-inline: 5%;
  margin-left: 11%;
}
@media (max-width: 1200px) {
  .page img {
    width: 50%;
    max-width: 600px;
    margin: 27px auto;
    border-radius: 10px;
    margin-top: 20%;
    margin-bottom: -7%;
    padding: 26px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
}
.foto1{
  width: 50%;
    max-width: 600px;
    margin: 27px auto;
    border-radius: 10px;
    margin-bottom: -11%;
   
    padding: 26px;
    box-shadow:2px 2px 10px rgb(0 0 0 / 30%);
    margin-top: 34%;
  }
.foto2{
    width: 50%;
      max-width: 600px;
      margin: 27px auto;
      border-radius: 10px;
      margin-bottom: -11%;
   
      padding: 26px;
    
      box-shadow:2px 2px 10px rgb(0 0 0 / 30%);
      margin-top: 22%;
  
  
   
    
}
.page p {
  font-size: 100%;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 34px;
  text-align: center;
  DISPLAY: inline-flex;
  /* ALIGN-ITEMS: center; */
  WIDTH: 43%;
  text-align: center;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  PADDING: 0.1%;
}
.page h1 {
  font-size: 3em;
  margin-top: 14px;
  margin-bottom: -85px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
}
.page .btn {
  border-radius: 20px;
  background-color: #1c2747;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 2%;
  margin-block-start: 9%;
  margin-inline: 5%;
  margin-left: 11%;
  margin-top: 16%;
}

  }