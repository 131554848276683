@import url('https://fonts.googleapis.com/css?family=Great+Vibes|Lato:300,400,700|Raleway:300,400,400i,500,600,600i,700|Roboto+Slab:wght@100;200;300;400;500;600;700;800;900|Permanent+Marker');

body {
  
  color: #1c2747;
  background: url("./assets/Images/so-white.png") repeat;
 padding: 0%;
 min-width: 666px;
 
}
@media only screen and (max-width: 767px) {
  .page {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
  }

  /* Texte */
  .page p {
    font-size: 20px;
    margin: 0 20px;
  }
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3498db;
  padding: 20px;
  color: white;
  animation-name: example;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.5s ease;
}

nav a:hover {
  color: #ecf0f1;
}

.logo {
  width:50px;
  height:50px;
  border-radius:15%;
  background:white;
  padding:5px;
  margin-right: auto;
  transition: transform 0.5s ease;
}
.logo:hover {
  transform: scale(1.2);
}
.required:after {
  content: " *";
  color: red;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.bg-instagram {
  background-color: #e1306c;
}

.bs-cover {
  background-size: cover;
}

.r-0 {
  right: 0;
}
.mw-180 {
  max-width: 180px;
}
.mw-140 {
  max-width: 140px;
}
.i-va{
  vertical-align: -0.125em;
}



/*                BTN PRIMARY OVERWRITE           */
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}


.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.btn-primary {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}

.btn-primary:hover {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #1c2747;
  border-color: #1c2747;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1c2747;
  border-color: #1c2747;
}

.btn-outline-primary {
  color: #1c2747;
  border-color: #1c2747;
}

.navbar-brand img{
  width: 100%;
}

.btnGroup .btn{
margin: 0px 10px;
}

#contact-button {
  background-image: linear-gradient(to right, #3498db, #0dcaf0b3);
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

#contact-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

#contact-button:active {
  transform: translateY(-1px);
  box-shadow: 0 6px 10px rgba(50, 50, 93, 0.11), 0 3px 6px rgba(0, 0, 0, 0.08);
}
@media (max-width: 576px) {
  /* styles pour les écrans mobiles */
  .my-element {
    max-width: 500px;
    margin: 0 auto;
  }
}