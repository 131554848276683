.logo-carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }
  
  .logo-carousel .prev {
    left: 0;
  }
  
  .logo-carousel .next {
    right: 0;
  }
  
  .logo-carousel img {
    width: 57%;
    height: 199%;
    display: block;
    margin: 2px auto;
}
  
.logo-carousel {
    position: relative;
    width: 33%;
    height: 60px;
    left: 0%;
    margin: 81px auto;
    margin-top: -1%;
    margin-bottom: 12%;
}

.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;}


    @media (max-width: 1200px) {
    .logo-carousel {
        position: relative;
        width: 66%;
        height: 60px;
        left: 0%;
        margin: 81px auto;
        margin-top: -2%;
        margin-bottom: 31%;
    }}