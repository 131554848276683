@import url(https://fonts.googleapis.com/css?family=Great+Vibes|Lato:300,400,700|Raleway:300,400,400i,500,600,600i,700|Roboto+Slab:wght@100;200;300;400;500;600;700;800;900|Permanent+Marker);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);
body {
  
  color: #1c2747;
  background: url(/static/media/so-white.db4ff89e.png) repeat;
 padding: 0%;
 min-width: 666px;
 
}
@media only screen and (max-width: 767px) {
  .page {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
  }

  /* Texte */
  .page p {
    font-size: 20px;
    margin: 0 20px;
  }
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3498db;
  padding: 20px;
  color: white;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.5s ease;
}

nav a:hover {
  color: #ecf0f1;
}

.logo {
  width:50px;
  height:50px;
  border-radius:15%;
  background:white;
  padding:5px;
  margin-right: auto;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.logo:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.required:after {
  content: " *";
  color: red;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.bg-instagram {
  background-color: #e1306c;
}

.bs-cover {
  background-size: cover;
}

.r-0 {
  right: 0;
}
.mw-180 {
  max-width: 180px;
}
.mw-140 {
  max-width: 140px;
}
.i-va{
  vertical-align: -0.125em;
}



/*                BTN PRIMARY OVERWRITE           */
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}


.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.btn-primary {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}

.btn-primary:hover {
    color: #fff;
    background-color: #1c2747;
    border-color: #1c2747;
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #1c2747;
  border-color: #1c2747;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1c2747;
  border-color: #1c2747;
}

.btn-outline-primary {
  color: #1c2747;
  border-color: #1c2747;
}

.navbar-brand img{
  width: 100%;
}

.btnGroup .btn{
margin: 0px 10px;
}

#contact-button {
  background-image: linear-gradient(to right, #3498db, #0dcaf0b3);
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

#contact-button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

#contact-button:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 6px 10px rgba(50, 50, 93, 0.11), 0 3px 6px rgba(0, 0, 0, 0.08);
}
@media (max-width: 576px) {
  /* styles pour les écrans mobiles */
  .my-element {
    max-width: 500px;
    margin: 0 auto;
  }
}
.required:after{content:" *";color:red}.google-maps{position:relative;padding-bottom:75%;height:0;overflow:hidden}.google-maps iframe{position:absolute;top:0;left:0;width:100%!important;height:100%!important}.bg-facebook{background-color:#3b5998}.bg-twitter{background-color:#1da1f2}.bg-google{background-color:#ea4335}.bg-instagram{background-color:#e1306c}.bs-cover{background-size:cover}.r-0{right:0}.mw-180{max-width:180px}.mw-140{max-width:140px}.i-va{vertical-align:-.125em}


.cOYrPE {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(28,39,71,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(247,250,252,var(--tw-text-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
}

.cOYrPE-Dark {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(247,250,252,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(28,39,71,var(--tw-text-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
}

.rounded-4 {
    border-radius: 2rem!important;
}

.py-6 {
   padding:150px 0!important;
}

.py-7 {
    padding:250px 0!important;
 }

.bg-primary {
    background-color: #1c2747!important;
}

.darkSection{
    background-color: #1c2747;
    color: #FFFFFF;
}

.cardDark{
    border-color: #1c2747;
}

.panneaux{
    height:700px;
    text-align: center;
    transition-property: background;
    transition-duration: 1s;
    transition-delay: 0s;
}

.panneau{
    width: 25%;
    height: 100%;
    transition-property: width;
    transition-duration: 1s;
    transition-delay: 0s;
}

.panneauHover{
    width: 30%;
}

.panneauFade {
    background-color: #000000;
    opacity: 0.4;
    -webkit-filter: blur(1);
            filter: blur(1);
}

.center-image {
    display: block;
    margin: 0px auto;
    width: 50%;
    max-width: 600px;
    border-radius: 64px;
    padding: 30px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-top: 30%;
    margin-bottom: -26%;
}
  @media (max-width: 1200px) {
  .center-image {
    display: block;
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    border-radius: 10px;
    padding: 26px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  
    margin-top: 34%;
    margin-bottom: -26%;
}
  }

.bg-1{
    background:center / cover no-repeat url(/static/media/securite.8f05e167.jpg);
}
.bg-2{
    background:center / cover no-repeat url(/static/media/elec.881d0927.jpg);
}
.bg-3{
    background:center / cover no-repeat url(/static/media/img-long1.8b137493.jpg);
}
.bg-4{
    background:center / cover no-repeat url(/static/media/depannage.7f6c05b8.jpg);
}

/* .panneau:hover{
    width: 30%;

} */

.navbar-brand img{
    height: 50px;
}

.banner-home-1{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url(/static/media/securite.8f05e167.jpg);
}

.banner-home-2{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url(/static/media/elec.881d0927.jpg);
}

.banner-home-3{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url(/static/media/img-long1.8b137493.jpg);
}

.banner-home-4{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url(/static/media/depannage.7f6c05b8.jpg);
}
.security {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.security h1 {
  margin-bottom: 50px;
  font-size: 45px;
  padding: 1%;
}

.security h2, .security h3, .security h4 {
  display: inline-block;
  margin-left: 50px;
  margin-right: 50px;
  padding: 8%;
  border: 1px solid lightgrey;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

  height: 100%;
  align-items: center;
  justify-content: center;

}

.security h2, .security h3, .security h4 {
  /* code précédent */
  width:26.5%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1px;; /* taille de police plus grande */
}
h2, h3, h4 {
  border-radius: 10px; /* ajouter des bords arrondis */
  overflow: hidden; /* cacher les éléments qui dépassent */
  position: relative; /* ajouter une position relative pour les ::before et ::after */
  width: 30%; /* ajouter une largeur fixe pour tous les h */
  height: 30%; /* ajouter une hauteur fixe pour tous les h */
  text-align: center;
}

h2::before, h3::before, h4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* ajouter une image de fond qui couvre tout le h */
  background-position: center;
}

h2::after, h3::after, h4::after {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 20px;
}

h2::before {
  background-image: url(/static/media/Ajax.f03cc523.jpg);
}

h3::before {
  background-image: url(/static/media/vidsurv.d006772f.jpg);
}

h4::before {
  background-image: url(/static/media/ca2.5f8ae496.jpg);
}

h2::after {
  content: "";
}

h3::after {
  content: "";
}

h4::after {
  content: "";
}


.security h2 .btn::before {
content: "Alarme";
font-size: 20px;font-weight: bold;
}

.security h3 .btn::before {
content: "Vidéosurveillance";
font-size: 20px;font-weight: bold;
}

.security h4 .btn::before {
content: "Contrôle d'accès";
font-size: 20px;font-weight: bold;
}

.security h2 .btn:hover,
.security h3 .btn:hover,
.security h4 .btn:hover {
   background-color: #fff;
   color: #333;
}
.p1 {
  margin-top: 10px;
  padding: 2%;
  font-size: 25px;
  text-align: justify;
}

.p2 {
  display: none;
  clear: both;
  margin-top: 10px;
  padding: 20px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}


.security i {
  font-size: 40px;
  margin-right: 10px;
}

.security h2, .security h3, .security h4 {
  cursor: pointer;
  tab-index: 0;
}

.security h2:focus + .p2, .security h3:focus + .p3, .security h4:focus + .p4 {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.5s linear;
}


.p3 {
  margin-right: 150px;
  margin-left: 150px;
  margin-top: 10px;
  display: block;
  padding: 20px;
  font-size: 20px;
  text-align: justify;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease; /* ajouter la transition */
}

.animation-appear {
  -webkit-transform: translateY(20px);
          transform: translateY(20px); /* déplacement en Y */
  opacity: 0; /* opacité initiale à 0 */
}
.h-container {
  display: flex;
 
 
  margin-bottom: 20px;
}
#btn6{
  margin-Left: 11.99%;
   font-Size: 105%;
}
#btn69{
  margin-Left: 24.5%;
margin-right: 23.9%;
 font-Size: 105%;
 margin-inline: 25%;
}
#btn696{
  margin-right: 9.8%;
   font-Size: 105% ;
 
}
@media (max-width: 1555px) {
  #btn69 {
    margin-Left: 24.5%;
    margin-right: 23.9%;
    font-Size: 105%;
    -webkit-margin-start: 15%;
            margin-inline-start: 15%;
    -webkit-margin-end: 11%;
            margin-inline-end: 11%;
}
#btn6 {
  margin-Left: 11.99%;
  font-Size: 105%;
}
.security h2, .security h3, .security h4 {
  width: 25.5%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1px;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    padding: 8%;
    border: 1px solid lightgrey;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
}






}

@media (max-width: 600px) {
  h2, h3, h4 {
    width: 31%;
    padding: 8%;
    height: 20%;
  }
  .security h2,.security h3,.security h4 {
    width: 31%; 
    padding: 8%;
    height: 20%; 
    }
  
  .p1{
    font-size: 99%;
  }
  #btn6{
    margin-left: 0.99%;
    font-size: 100%;
}
  
  #btn69{
    font-size: 100%;
    -webkit-margin-start: 15%;
            margin-inline-start: 15%;
    -webkit-margin-end: 10%;
            margin-inline-end: 10%;
  }
  #btn696{
    margin-right: -3.2%;
    font-Size: 100%;
  }
  .security h2, .security h3, .security h4 {
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    padding: 8%;
    border: 1px solid lightgrey;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.h1{
  font-size: 220%;
}
}
.page {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* Titre */
.page h1 {
  font-size: 3em;
  margin-top: 27px;
  margin-bottom: 35px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
}

/* Images */
.page img {
  width: 100%;
  max-width: 520px;
  margin: 30px auto;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
}
/* Texte */
.page p {
  font-size: 130%;
  line-height: 30px;
  margin-bottom: 0px;
  margin-top: 34px;
  text-align: center;
  DISPLAY: inline-flex;
  /* ALIGN-ITEMS: center; */
  WIDTH: 48%;
  text-align: center;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  PADDING: 2%;
}

/* Bouton */
.page .btn {
  border-radius: 20px;
  background-color: #1c2747;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 2%;
  -webkit-margin-before: 24%;
          margin-block-start: 24%;
  margin-inline: 5%;
  margin-left: 11%;
}
@media (max-width: 1200px) {
  .page img {
    width: 50%;
    max-width: 600px;
    margin: 27px auto;
    border-radius: 10px;
    margin-top: 20%;
    margin-bottom: -7%;
    padding: 26px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
}
.foto1{
  width: 50%;
    max-width: 600px;
    margin: 27px auto;
    border-radius: 10px;
    margin-bottom: -11%;
   
    padding: 26px;
    box-shadow:2px 2px 10px rgb(0 0 0 / 30%);
    margin-top: 34%;
  }
.foto2{
    width: 50%;
      max-width: 600px;
      margin: 27px auto;
      border-radius: 10px;
      margin-bottom: -11%;
   
      padding: 26px;
    
      box-shadow:2px 2px 10px rgb(0 0 0 / 30%);
      margin-top: 22%;
  
  
   
    
}
.page p {
  font-size: 100%;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 34px;
  text-align: center;
  DISPLAY: inline-flex;
  /* ALIGN-ITEMS: center; */
  WIDTH: 43%;
  text-align: center;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  PADDING: 0.1%;
}
.page h1 {
  font-size: 3em;
  margin-top: 14px;
  margin-bottom: -85px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
}
.page .btn {
  border-radius: 20px;
  background-color: #1c2747;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 2%;
  -webkit-margin-before: 9%;
          margin-block-start: 9%;
  margin-inline: 5%;
  margin-left: 11%;
  margin-top: 16%;
}

  }
/* CSS */
.titre-principal {
    font-size: 2em;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
    }
    
    .sous-titre {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    }
    
    .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    }
    
    p {
    font-size: 1.1em;
    margin-top: 20px;
    margin-bottom: 20px;
    }
    
    /* Marge à droite et à gauche de la page */
    .container {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    text-align: center;
    }
.logo-carousel button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 36px;
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }
  
  .logo-carousel .prev {
    left: 0;
  }
  
  .logo-carousel .next {
    right: 0;
  }
  
  .logo-carousel img {
    width: 57%;
    height: 199%;
    display: block;
    margin: 2px auto;
}
  
.logo-carousel {
    position: relative;
    width: 33%;
    height: 60px;
    left: 0%;
    margin: 81px auto;
    margin-top: -1%;
    margin-bottom: 12%;
}

.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;}


    @media (max-width: 1200px) {
    .logo-carousel {
        position: relative;
        width: 66%;
        height: 60px;
        left: 0%;
        margin: 81px auto;
        margin-top: -2%;
        margin-bottom: 31%;
    }}
#contact-button {
    position: fixed;
    bottom:5%;
    right: 5%;
  }
