@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
.security {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.security h1 {
  margin-bottom: 50px;
  font-size: 45px;
  padding: 1%;
}

.security h2, .security h3, .security h4 {
  display: inline-block;
  margin-left: 50px;
  margin-right: 50px;
  padding: 8%;
  border: 1px solid lightgrey;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

  height: 100%;
  align-items: center;
  justify-content: center;

}

.security h2, .security h3, .security h4 {
  /* code précédent */
  width:26.5%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1px;; /* taille de police plus grande */
}
h2, h3, h4 {
  border-radius: 10px; /* ajouter des bords arrondis */
  overflow: hidden; /* cacher les éléments qui dépassent */
  position: relative; /* ajouter une position relative pour les ::before et ::after */
  width: 30%; /* ajouter une largeur fixe pour tous les h */
  height: 30%; /* ajouter une hauteur fixe pour tous les h */
  text-align: center;
}

h2::before, h3::before, h4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* ajouter une image de fond qui couvre tout le h */
  background-position: center;
}

h2::after, h3::after, h4::after {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 20px;
}

h2::before {
  background-image: url('../../../img/Ajax.jpg');
}

h3::before {
  background-image: url('../../../img/vidsurv.jpg');
}

h4::before {
  background-image: url('../../../img/ca2.jpg');
}

h2::after {
  content: "";
}

h3::after {
  content: "";
}

h4::after {
  content: "";
}


.security h2 .btn::before {
content: "Alarme";
font-size: 20px;font-weight: bold;
}

.security h3 .btn::before {
content: "Vidéosurveillance";
font-size: 20px;font-weight: bold;
}

.security h4 .btn::before {
content: "Contrôle d'accès";
font-size: 20px;font-weight: bold;
}

.security h2 .btn:hover,
.security h3 .btn:hover,
.security h4 .btn:hover {
   background-color: #fff;
   color: #333;
}
.p1 {
  margin-top: 10px;
  padding: 2%;
  font-size: 25px;
  text-align: justify;
}

.p2 {
  display: none;
  clear: both;
  margin-top: 10px;
  padding: 20px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}


.security i {
  font-size: 40px;
  margin-right: 10px;
}

.security h2, .security h3, .security h4 {
  cursor: pointer;
  tab-index: 0;
}

.security h2:focus + .p2, .security h3:focus + .p3, .security h4:focus + .p4 {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.5s linear;
}


.p3 {
  margin-right: 150px;
  margin-left: 150px;
  margin-top: 10px;
  display: block;
  padding: 20px;
  font-size: 20px;
  text-align: justify;
  transition: transform 0.5s ease; /* ajouter la transition */
}

.animation-appear {
  transform: translateY(20px); /* déplacement en Y */
  opacity: 0; /* opacité initiale à 0 */
}
.h-container {
  display: flex;
 
 
  margin-bottom: 20px;
}
#btn6{
  margin-Left: 11.99%;
   font-Size: 105%;
}
#btn69{
  margin-Left: 24.5%;
margin-right: 23.9%;
 font-Size: 105%;
 margin-inline: 25%;
}
#btn696{
  margin-right: 9.8%;
   font-Size: 105% ;
 
}
@media (max-width: 1555px) {
  #btn69 {
    margin-Left: 24.5%;
    margin-right: 23.9%;
    font-Size: 105%;
    margin-inline-start: 15%;
    margin-inline-end: 11%;
}
#btn6 {
  margin-Left: 11.99%;
  font-Size: 105%;
}
.security h2, .security h3, .security h4 {
  width: 25.5%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1px;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    padding: 8%;
    border: 1px solid lightgrey;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
}






}

@media (max-width: 600px) {
  h2, h3, h4 {
    width: 31%;
    padding: 8%;
    height: 20%;
  }
  .security h2,.security h3,.security h4 {
    width: 31%; 
    padding: 8%;
    height: 20%; 
    }
  
  .p1{
    font-size: 99%;
  }
  #btn6{
    margin-left: 0.99%;
    font-size: 100%;
}
  
  #btn69{
    font-size: 100%;
    margin-inline-start: 15%;
    margin-inline-end: 10%;
  }
  #btn696{
    margin-right: -3.2%;
    font-Size: 100%;
  }
  .security h2, .security h3, .security h4 {
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    padding: 8%;
    border: 1px solid lightgrey;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.h1{
  font-size: 220%;
}
}