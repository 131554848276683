/* CSS */
.titre-principal {
    font-size: 2em;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
    }
    
    .sous-titre {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    }
    
    .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    }
    
    p {
    font-size: 1.1em;
    margin-top: 20px;
    margin-bottom: 20px;
    }
    
    /* Marge à droite et à gauche de la page */
    .container {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    text-align: center;
    }