

.cOYrPE {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(28,39,71,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(247,250,252,var(--tw-text-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
}

.cOYrPE-Dark {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(247,250,252,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(28,39,71,var(--tw-text-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
}

.rounded-4 {
    border-radius: 2rem!important;
}

.py-6 {
   padding:150px 0!important;
}

.py-7 {
    padding:250px 0!important;
 }

.bg-primary {
    background-color: #1c2747!important;
}

.darkSection{
    background-color: #1c2747;
    color: #FFFFFF;
}

.cardDark{
    border-color: #1c2747;
}

.panneaux{
    height:700px;
    text-align: center;
    transition-property: background;
    transition-duration: 1s;
    transition-delay: 0s;
}

.panneau{
    width: 25%;
    height: 100%;
    transition-property: width;
    transition-duration: 1s;
    transition-delay: 0s;
}

.panneauHover{
    width: 30%;
}

.panneauFade {
    background-color: #000000;
    opacity: 0.4;
    filter: blur(1);
}

.center-image {
    display: block;
    margin: 0px auto;
    width: 50%;
    max-width: 600px;
    border-radius: 64px;
    padding: 30px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
    transform: translateY(-50%);
    margin-top: 30%;
    margin-bottom: -26%;
}
  @media (max-width: 1200px) {
  .center-image {
    display: block;
    margin: 0 auto;
    width: 50%;
    max-width: 600px;
    border-radius: 10px;
    padding: 26px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
    transform: translateY(-50%);
  
    margin-top: 34%;
    margin-bottom: -26%;
}
  }

.bg-1{
    background:center / cover no-repeat url("../../../img/securite.jpg");
}
.bg-2{
    background:center / cover no-repeat url("../../../img/elec.jpg");
}
.bg-3{
    background:center / cover no-repeat url("../../../img/img-long1.jpg");
}
.bg-4{
    background:center / cover no-repeat url("../../../img/depannage.jpg");
}

/* .panneau:hover{
    width: 30%;

} */

.navbar-brand img{
    height: 50px;
}

.banner-home-1{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url("../../../img/securite.jpg");
}

.banner-home-2{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url("../../../img/elec.jpg");
}

.banner-home-3{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url("../../../img/img-long1.jpg");
}

.banner-home-4{
    width: 100%;
    height: 150px;
    background:center / cover no-repeat url("../../../img/depannage.jpg");
}